import * as Sentry from "@sentry/vue";

export default function ({ req, route, redirect, $axios, store, error, $churnZero }) {
    if (req && !req.user) {
        if (route.path !== '/login') {
            return redirect('/login');
        }
    }
    if (!store.state.user.id) {
        if (store.state.user.id === undefined) {
            return $axios.$get('/user').then(user => {
                store.commit('user', user);
                Sentry.setUser({ id: user.id, role: user.role, demo: user.demo || user.local || false });
                $churnZero.triggerEvent('USER_LOGGED_IN', user.hubspot_account, user.email);
                return;
            }).catch(() => {
                store.commit('user', { id: false });
                return error({ statusCode: 401 });
            });
        }
        return redirect(process.env.LOGIN_URL);
    }
    if (!route.path.startsWith('/community') && store.state.user.platforms && !store.state.user.platforms.includes('cs')) {
        return redirect('/community');
    }
    
}