// Export the churnZeroEvents object for external use
const createEvent = (eventName, description) => ({
    eventName,
    description,
    quantity: 1,
});

export const churnZeroEvents = {
    USER_LOGGED_IN: createEvent('Login', 'User logged into CS platform'),
    MANAGE_STUDENTS: createEvent('Manage Students CS', 'User clicked on Manage Students in CS'),
    MANAGE_SECTIONS: createEvent('Manage Sections CS', 'User clicked on Manage Sections in CS'),
    MANAGE_CURRICULUM: createEvent('Manage Curriculum', 'User clicked on Manage Curriculum'),
    MANAGE_ASSESSMENTS: createEvent('Manage Assessments', 'User clicked on Manage Assessments'),
    STUDENT_PROGRESS: createEvent('Student Progress CS', 'User clicked on Student Progress'),
    GROUP_PROJECTS: createEvent('Group Projects', 'User clicked on Group Projects'),
    HELP_STUDENTS: createEvent('Help Students', 'User clicked on Help Students'),
    SETTINGS: createEvent('Settings', 'User clicked on Settings'),
    LESSON_PLANS: createEvent('Lesson Plans', 'User clicked on Lesson Plans'),
    COMMUNITY: createEvent('Community', 'User clicked on Community'),
    ACTIVITY_GENERATOR: createEvent('Activity Generator', 'User clicked on Activity Generator'),
    TUTOR_BANK: createEvent('Tutor Bank', 'User clicked on Tutor Bank'),
    CLASSROOM_RESOURCES: createEvent('Classroom Resources', 'User clicked on Classroom Resources'),
    COMMUNITY_PLUS: createEvent('Community Plus', 'User clicked on Community Plus'),
    SUPPORT_IN_COMMUNITY: createEvent('Support in Community', 'User clicked on "Support" in Community'),
    EVENTS_IN_COMMUNITY: createEvent('Events in Community', 'User clicked on Events in Community'),
    BLOG_IN_COMMUNITY: createEvent('Blog in Community', 'User clicked on Blog in Community'),
    ACTIVITIES: createEvent('Activities', 'User clicked on Activities'),
    ANSWER_KEYS: createEvent('Answer Keys', 'User clicked on Answer Keys'),
    GETTING_STARTED_WITH_SKILL_STRUCK: createEvent('Getting Started with Skill Struck', 'User clicked on Getting Started with Skill Struck'),
    VALUE_OF_CS: createEvent('The Value of CS', 'User clicked on The Value of CS'),
    COURSE_INFORMATION: createEvent('Course Information', 'User clicked on Course Information'),
    CANVAS_COURSES: createEvent('Canvas Courses', 'User clicked on Canvas Courses'),
    CANVAS_COURSE_DOWNLOAD: createEvent('Canvas Course Download', 'User downloaded Canvas Course'),
    EDIT_SECTION: createEvent('Edit Section', 'User clicked "Edit Section" button in Section: {name}'),
    CURRICULUM_DROPDOWN: createEvent('Curriculum Dropdown', 'User clicked curriculum: {name}'),
    STUDENT_PROGRESS_CSV_DOWNLOAD: createEvent('Student Progress CSV Download', 'User downloaded student progress CSV'),
    ADD_SECTION: createEvent('Add Section CS', 'User added section: {name}'),
    ADD_STUDENT: createEvent('Add Student CS', 'User added student'),
    LESSON_PLAN_DROPDOWN: createEvent('Lesson Plan Dropdown', 'User clicked lesson plan {name}'),
    CHAT_PORTAL: createEvent('Chat Portal', 'User clicked chat portal'),
    LAUNCH_PAD: createEvent('Launch Pad (Teacher View)', 'User clicked launch pad (teacher view)'),
    VOYAGE: createEvent('Voyage (Teacher View)', 'User clicked voyage (teacher view)'),
    TYPE_STATION: createEvent('Type Station (Teacher View)', 'User clicked type station (teacher view)'),
    ROBOTICS: createEvent('Robotics (Teacher View)', 'User clicked robotics (teacher view)'),
    CHAT_FOR_SCHOOLS: createEvent('Chat for Schools (Teacher View)', 'User clicked Chat for Schools (teacher view)'),
    ADMIN_PORTAL: createEvent('Admin Portal', 'User clicked Admin Portal'),
};

class ChurnZero {
    constructor() {
        this.externalAccountId = null;
        this.externalContactId = null;
        this.ChurnZero = null;
        this.initialized = false;
    }

    init(externalAccountId, externalContactId, eventKey = null) {
        try {
            if (process.env.STAGE !== 'prod') return;
            if (!externalAccountId || !externalContactId) {
                console.error('ChurnZero: ExternalAccountId and ExternalContactId are required.');
                return;
            }
            this.externalAccountId = externalAccountId;
            this.externalContactId = externalContactId;
            const cz = document.createElement('script'); 
            cz.type = 'text/javascript';
            cz.async = true;
            cz.src = 'https://skillstruck.us1app.churnzero.net/churnzero.js';
            cz.onload = () => {
                try {
                    if (window.ChurnZero) {
                        this.ChurnZero = window.ChurnZero;
                        this.initialized = true;
                        this.ChurnZero.push(['setAppKey', '1!p7YqleqfrcbEbd8tGQCFg9KzFgmoy1IMVK-UsrKH8agt150A']);
                        this.ChurnZero.push(['setContact', this.externalAccountId, this.externalContactId]);
                        if (eventKey) {
                            this.triggerEvent(eventKey, this.externalAccountId, this.externalContactId)
                        }
                    } else {
                        console.error('ChurnZero: Script loaded but ChurnZero is undefined.');
                    }
                } catch (error) {
                    console.error('ChurnZero: Error during script onload execution.', error);
                }
            }
            cz.onerror = () => console.error('Failed to load ChurnZero script.');
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(cz, s);
        }  catch (error) {
            console.error('ChurnZero: Error in triggerEvent method.', error);
        }
    }
    triggerEvent(eventKey, externalAccountId = null, externalContactId = null, additionalCustomFields = {}, config = {}) {
        try {
            if(!this.externalAccountId || !this.externalContactId || !this.initialized){
                this.init(externalAccountId, externalContactId, eventKey); 
            }
            const event = churnZeroEvents[eventKey];
            if (!event) {
                console.error(`ChurnZero: Event "${eventKey}" not defined.`);
                return;
            }
            const { eventName, description, quantity } = event;
            const parsedDescription = this.parseDescription(description, config);
            if(this.ChurnZero){
                this.ChurnZero.push(['trackEvent', eventName, parsedDescription, quantity, additionalCustomFields]);
            }
        } catch (error) {
            console.error(error);
        }
    }
    parseDescription(description, config = {}) {
        try {
            if (!description || Object.keys(config).length === 0) {
                return description;
            }
            return description.replace(/\{(\w+)\}/g, (_, key) => config[key] || `{${key}}`);
        } catch (error) {
            console.error('ChurnZero: Error in parseDescription method.', error);
            return description;
        }
    }
}

// Export the ChurnZero class as the default export
export default ({ app }, inject) => {
    const ChurnZeroManager = new ChurnZero();
    inject('churnZero', ChurnZeroManager);
};
