import { faRecordVinyl } from "@fortawesome/pro-duotone-svg-icons";

export const state = () => ({
    user: {},
    adminSections: [],
    adminStudents: [],
    campusURL: 'https://campus.skillstruck.com',
    students: [],
    sections: [],
    schoolSections: [],
    lessons: [],
    curriculumLessons: [],
    curriculumAssessments: [],
    unlockedCoursesUnitsLessons: [],
    units: [],
    unitLessons: {},
    unitTypingLessons: {},
    unitTypingPracticeLessons: {},
    unitTypingProgress: {},
    latestUnitTypingProgress: {},
    typingLessonProgress: {},
    latestTypingLessonProgress: {},
    unitTypingPracticeProgress: {},
    latestUnitTypingPracticeProgress: {},
    typingPracticeLessonProgress: {},
    latestTypingPracticeLessonProgress: {},
    scenesLessons: {},
    scenesUnits: {},
    scenesProgress: {},
    scenesLessonProgress: {},
    microbitUnitLessons: {},
    customTypingLessons: [],
    customTypingProgress: {},
    customTypingLessonProgress: {},
    selectedUnit: null,
    selectedMicrobitUnit: null,
    selectedLesson: null,
    selectedTypingUnit: null,
    selectedTypingPracticeUnit: null,
    selectedTypingLesson: null,
    selectedTypingPracticeLesson: null,
    selectedScenesUnit: null,
    selectedScenesLesson: null,
    selectedMicrobitLesson: null,
    selectedCustomTypingLesson: null,
    selectedAssessment: null,
    selectedCustomAssessment: null,
    unitProgress: {},
    latestUnitProgress: {},
    latestIntegrationsUnitProgress: {},
    integrationsUnitProgress: {},
    microbitUnitProgress: {},
    lessonProgress: {},
    integrationsLessonProgress: {},
    latestLessonProgress: {},
    latestIntegrationsLessonProgress: {},
    assessmentProgress: {},
    microbitLessonProgress: {},
    lessonActivities: {},
    assessments: [],
    assessmentsLoaded: false,
    submissions: [],
    challenges: {},
    checkpoints: {},
    puzzles: {},
    plans: null,
    trackGroups: [],
    translatedTrackGroups: {},
    tracks: [],
    translatedTracks: {},
    projects: [],
    threads: [],
    lastTimeLessonProgressUpdated: "",
    lastTimeExamProgressUpdated: "",
    lastTimeKeyboardingProgressUpdated: '',
    lastTimeSceneProgressUpdated: '',
    lastTimeCustomTypingProgressUpdated: '',
    lastTimeCustomExamProgressUpdated: '',
    lessonAnswers: {},
    progress: [],
    examProgress: {},
    trackProgress: {},
    currentSection: {},
    notifications: [],
    loaded: false,
    hasTutorial: false,
    hasPastSections: false,
    typingUnits: [],
    typingPracticeUnits: [],
    microbitUnits: [],
    typingProgress: [],
    typingResults: {},
    customTypingAssignments: [],
    customTypingProgress: [],
    customTypingAssignmentResults: {},
    customAssessments: [],
    customAssessmentsLoaded: false,
    customAssessmentProgress: {},
    announcements: [],
    scrollPosition: 0,
    gClassroomCourses: null,
    topics: [],
    searchResults: {},
    courses: [],
    sceneGroups: [],
    sceneProgress: [],
    sceneResults: {},
    districtData: null,
    districtProgress: null,
    originalSectionOrder: null,
    // ADMIN DATA
    schools: [],
    moreSchools: false,
    totalSchools: 0,
    schoolData: {},
    schoolProgress: {},
    teachers: [],
    moreTeachers: false,
    totalTeachers: 0,
    teacherData: {},
    teacherProgress: {},
    studentProgress: {},
    studentTypingProgress: {},
    quizAttemptsProgress: {},
    showLockedCurriculum: false,
    quizQuestions: {},
    latestQuizAttemptsProgress: {},
    viewAllLatestProgress: false,
    viewIntegrationsProgress: false,
    viewTimestamps: false,
    plansLanguage: 'en',
    usersTypingStats: {},
    usersTypingSettings: {}
});

export const mutations = {
    user(state, user) {
        state.user = user;
        state.campusURL = user.test ? 'https://test-campus.skillstruck.com' : 'https://campus.skillstruck.com';
    },
    person(state, person) {
        state.user.person = person;
        state.user = { ...state.user };
    },
    adminSections(state, sections) {
        state.adminSections = sections;
    },
    adminStudents(state, students) {
        state.adminStudents = students;
    },
    schools(state, schools) {
        state.schools = schools;
    },
    updateSchools(state, data = { schools: [], moreSchools: false, totalSchools: 0 }) {
        data.schools.forEach(school => {
            const index = state.schools.findIndex(o => o.id === school.id);
            if (index >= 0) state.schools[index] = school;
            else state.schools.push(school);
        });
        state.schools = [...state.schools];
        state.moreSchools = data.more;
        state.totalSchools = data.total;
    },
    updateTeachers(state, data = { teachers: [], moreTeachers: false, moreTeachers: 0 }) {
        data.teachers.forEach(teacher => {
            const index = state.teachers.findIndex(o => o.id === teacher.id);
            if (index >= 0) state.teachers[index] = teacher;
            else state.teachers.push(teacher);
        });
        state.teachers = [...state.teachers];
        state.moreTeachers = data.more;
        state.totalTeachers = data.total;
    },
    teachers(state, teachers) {
        state.teachers = teachers;
    },
    school(state, school) {
        state.schools.push(school);
        state.schools = [...state.schools];
    },
    updateSchool(state, school) {
        const index = state.schools.findIndex(o => o.id === school.id);
        state.schools[index] = { ...state.schools[index], ...school };
        state.schools = [...state.schools];
    },
    removeSchool(state, school) {
        state.schools = state.schools.filter(o => o.id !== school.id);
        state.schools = [...state.schools];
    },
    principal(state, principal) {
        const index = state.schools.findIndex(o => o.id === principal.school);
        delete principal.school;
        state.schools[index].principals.push(principal);
        state.schools = [...state.schools];
    },
    removePrincipal(state, principal) {
        const schoolIndex = state.schools.findIndex(o => o.id === principal.school);
        state.schools[schoolIndex].principals = state.schools[schoolIndex].principals.filter(o => o.id !== principal.id);
        state.schools = [...state.schools];
    },
    updatePrincipal(state, principal) {
        const schoolIndex = state.schools.findIndex(o => o.id === principal.school);
        const principalIndex = state.schools[schoolIndex].principals.findIndex(o => o.id === principal.id);
        delete principal.school;
        state.schools[schoolIndex].principals[principalIndex] = principal;
        state.schools = [...state.schools];
    },
    teacher(state, teacher) {
        const index = state.schools.findIndex(o => o.id === teacher.school);
        delete teacher.school;
        if (index >= 0) {
            const teachers = state.schools[index].teachers ? state.schools[index].teachers : [];
            teachers.push(teacher);
            const count = state.schools[index].teacher_count;
            state.schools[index] = { ...state.schools[index], teachers: teachers, teacher_count: count + 1 };
            state.schools = [...state.schools];
        }
    },
    removeTeacher(state, teacher) {
        const schoolIndex = state.schools.findIndex(o => o.id === teacher.school);
        state.schools[schoolIndex].teachers = state.schools[schoolIndex].teachers.filter(o => o.id !== teacher.id);
        state.schools[schoolIndex].teacher_count = state.schools[schoolIndex].teachers.length;
        state.schools = [...state.schools];
    },
    updateTeacher(state, teacher) {
        const schoolIndex = state.schools.findIndex(o => o.id === teacher.school);
        const teacherIndex = state.schools[schoolIndex].teachers.findIndex(o => o.id === teacher.id);
        delete teacher.school;
        state.schools[schoolIndex].teachers[teacherIndex] = teacher;
        state.schools = [...state.schools];
    },
    hasPastSections(state, value) {
        state.hasPastSections = value;
    },
    pastSections(state, sections) {
        state.hasPastSections = false;
        state.sections = [...state.sections, ...sections.map(o => {
            return { ...o, past: true };
        })];
    },
    students(state, students) {
        state.students = students;
        state.loaded = true;
    },
    updateStudents(state, students) {
        const ids = students.map(o => o.id);
        for (let i = 0; i < state.students.length; i++) {
            if (ids.includes(state.students[i].id)) {
                state.students[i] = students.find(o => o.id === state.students[i].id);
            }
        }
        state.students = [...state.students];
    },
    student(state, student) {
        const index = state.students.findIndex(o => o.id === student.id);
        if (index >= 0) {
            state.students[index] = student;
        } else {
            state.students.push(student);
        }
        state.students = [...state.students];
    },
    removeStudent(state, student) {
        const index = state.students.findIndex(o => o.id === student.id);
        if (index >= 0) {
            state.students.splice(index, 1);
            state.students = [...state.students];
        }
    },
    pending(state, pending) {
        const index = state.students.findIndex(o => o.pending === pending.pending);
        if (index >= 0) {
            state.students[index] = pending;
        }
        state.students = [...state.students];
    },
    removePending(state, pending) {
        const index = state.students.findIndex(o => o.pending === pending.pending);
        if (index >= 0) {
            state.students.splice(index, 1);
            state.students = [...state.students];
        }
    },
    sections(state, sections) {
        state.sections = sections;
    },
    section(state, section) {
        const index = state.sections.findIndex(o => o.id === section.id);
        if (!section.reqs) section.reqs = { challenges: 0, puzzles: 0, games: 0 };
        if (section.required_challenges) {
            section.reqs.challenges = Number(section.required_challenges);
        }
        if (section.required_puzzles) {
            section.reqs.puzzles = Number(section.required_puzzles);
        }
        if (section.required_games) {
            section.reqs.games = Number(section.required_games);
        }
        if (section.quiz_attempts) {
            section.reqs.quiz = Number(section.quiz_attempts);
        }
        if (index >= 0) {
            if (!section.name) section.name = 'Section ' + (index + 1);
            state.sections[index] = section;
        } else {
            if (!section.name) section.name = 'Section ' + (state.sections.length + 1);
            state.sections.push(section);
        }
        if (!state.currentSection.id) {
            state.currentSection = section;
        }
        state.sections = [...state.sections];
    },
    removeSection(state, section) {
        const index = state.sections.findIndex(o => o.id === section.id);
        if (index >= 0) {
            state.sections.splice(index, 1);
            state.sections = [...state.sections];
            if (state.currentSection.id === section.id) {
                if (state.sections.length < 1) {
                    state.currentSection = {};
                } else {
                    state.currentSection = state.sections[0];
                }
            }
        }
    },
    removeChangedSections(state) {
        state.sections.filter(o => o.changed === true).forEach(section => {
            delete section.changed;
        });
        state.sections = [...state.sections];
    },
    plans(state, plans) {
        state.plans = plans;
    },
    tracks(state, tracks) {
        state.tracks = tracks;
    },
    translatedTracks(state, data) {
        state.translatedTracks[data.lang] = data.tracks;
        state.translatedTracks = { ...state.translatedTracks };
    },
    track(state, track) {
        const index = state.tracks.findIndex(o => o.id === track.id);
        if (index >= 0) {
            state.tracks[index] = { ...state.tracks[index], ...track };
        } else {
            state.tracks.push(track);
        }
        if (track.group) {
            const groupIndex = state.trackGroups.findIndex(o => o.id === track.group);
            if (groupIndex >= 0) {
                const groupTrackIndex = state.trackGroups[groupIndex].tracks.findIndex(o => o.id === track.id);
                if (groupTrackIndex >= 0) {
                    state.trackGroups[groupIndex].tracks[groupTrackIndex] = { ...state.trackGroups[groupIndex].tracks[groupTrackIndex], ...track };
                    state.trackGroups = [...state.trackGroups];
                }
            }
        }
    },
    translatedTrack(state, data) {
        const lang = data.lang;
        const track = data.track;
        let tracks = state.translatedTracks[lang];
        const index = tracks.findIndex(o => o.id === track.id);
        if (index >= 0) {
            tracks[index] = { ...tracks[index], ...track };
        } else {
            tracks.push(track);
        }
        state.translatedTracks[lang] = tracks;
        if (track.group) {
            let groups = state.translatedTrackGroups[lang];
            const groupIndex = groups.findIndex(o => o.id === track.group);
            if (groupIndex >= 0) {
                const groupTrackIndex = groups[groupIndex].tracks.findIndex(o => o.id === track.id);
                if (groupTrackIndex >= 0) {
                    groups[groupIndex].tracks[groupTrackIndex] = { ...groups[groupIndex].tracks[groupTrackIndex], ...track };
                    groups = [...groups];
                }
            }
            state.translatedTrackGroups[lang] = groups;
        }
        state.translatedTracks = { ...state.translatedTracks };
        state.translatedTrackGroups = { ...state.translatedTrackGroups };
    },
    trackGroups(state, groups) {
        state.trackGroups = groups;
    },
    translatedTrackGroups(state, data) {
        state.translatedTrackGroups[data.lang] = data.trackGroups;
        state.translatedTrackGroups = { ...state.translatedTrackGroups };
    },
    addPlan(state, plan) {
        const track = state.tracks.find(o => o.id === plan.track);
        const index = track.plans ? track.plans.findIndex(o => o.id === plan.id) : -1;
        if (index >= 0) {
            track.plans[index] = plan;
        } else {
            track.plans = [plan];
        }
        state.tracks = [...state.tracks];
    },
    addTranslatedPlan(state, data) {
        const lang = data.lang;
        const plan = data.plan;
        const track = state.translatedTracks[lang] && state.translatedTracks[lang].find(o => o.id === plan.track);
        const index = track.plans ? track.plans.findIndex(o => o.id === plan.id) : -1;
        if (index >= 0) {
            track.plans[index] = plan;
        } else {
            track.plans = [plan];
        }
        state.translatedTracks[lang] = [...state.translatedTracks[lang], track];
    },
    lessons(state, lessons) {
        state.lessons = lessons;
    },
    curriculumLessons(state, lessons) {
        state.curriculumLessons = lessons;
    },
    curriculumAssessments(state, assessments) {
        state.curriculumAssessments = assessments;
    },
    units(state, units) {
        state.units = units;
    },
    unlockedCoursesUnitsLessons(state, courses) {
        state.unlockedCoursesUnitsLessons = courses;
    },
    unit(state, unit) {
        const index = state.units.findIndex(o => o.id === unit.id);
        if (index >= 0) {
            state.units[index] = { ...state.units[index], ...unit };
        } else {
            state.units.push(unit);
        }
        state.units = [...state.units];
    },
    scenesUnits(state, units) {
        state.scenesUnits = units;
    },
    selectedUnit(state, unit) {
        state.selectedUnit = unit;
    },
    selectedMicrobitUnit(state, unit) {
        state.selectedMicrobitUnit = unit;
    },
    selectedLesson(state, lesson) {
        state.selectedLesson = lesson;
    },
    selectedScenesUnit(state, unit) {
        state.selectedScenesUnit = unit;
    },
    selectedScenesLesson(state, lessonId) {
        state.selectedScenesLesson = lessonId;
    },
    selectedMicrobitLesson(state, lesson) {
        state.selectedMicrobitLesson = lesson;
    },
    selectedCustomTypingLesson(state, lesson) {
        state.selectedCustomTypingLesson = lesson;
    },
    selectedAssessment(state, assessment) {
        state.selectedAssessment = assessment;
    },
    selectedCustomAssessment(state, assessment) {
        state.selectedCustomAssessment = assessment;
    },
    unitProgress(state, data) {
        const progressKey = getUnitProgressKey(state);
        if (data.sectionId) {
            state[progressKey][`${data.unitId}-${data.sectionId}`] = data.progress;
        } else {
            state[progressKey][data.unitId] = data.progress;
        }
        state[progressKey] = { ...state[progressKey] };
    },
    clearUnitProgress(state, { unitId, sectionId }) {
        const progressKey = getUnitProgressKey(state);
        const key = `${unitId}-${sectionId}`;
        if (state[progressKey][key]) {
            delete state[progressKey][key];
            state[progressKey] = { ...state[progressKey] };
        }
    },
    studentProgress(state, data) {
        const key = data.sectionId ? `${data.unitId}-${data.sectionId}` : `${data.unitId}`;
        state.studentProgress[data.studentId] = {};
        state.studentProgress[data.studentId][key] = data.progress;
        state.studentProgress = { ...state.studentProgress };
    },
    studentTypingProgress(state, data) {
        const key = data.sectionId ? `${data.unitId}-${data.sectionId}` : `${data.unitId}`;
        state.studentTypingProgress[data.studentId] = {};
        state.studentTypingProgress[data.studentId][key] = data.progress;
        state.studentTypingProgress = { ...state.studentTypingProgress };
    },
    microbitUnitProgress(state, data) {
        if (data.sectionId) {
            state.microbitUnitProgress[`${data.unitId}-${data.sectionId}`] = data.progress;
        } else {
            state.microbitUnitProgress[data.unitId] = data.progress;
        }
        state.microbitUnitProgress = { ...state.microbitUnitProgress };
    },
    lessonProgress(state, data) {
        const progressKey = getLessonProgressKey(state);
        const key = data.sectionId ? `${data.lessonId}-${data.sectionId}` : `${data.lessonId}`;
        if (!state[progressKey][key]) {
            state[progressKey][key] = {};
        }
        state[progressKey][key][data.type] = data.progress;
        state[progressKey] = { ...state[progressKey] };
    },
    quizAttemptsProgress(state, data) {
        const progressKey = state.viewAllLatestProgress ? 'latestQuizAttemptsProgress' : 'quizAttemptsProgress';
        const key = data.sectionId ? `${data.lessonId}-${data.sectionId}` : `${data.lessonId}`;
        if (!state[progressKey][key]) {
            state[progressKey][key] = {};
        }
        state[progressKey][key][data.studentId] = data.progress;
        state[progressKey] = { ...state[progressKey] };
    },
    updateStudentLessonProgress(state, data) {
        const key = data.sectionId ? `${data.lessonId}-${data.sectionId}` : `${data.lessonId}`;
        const activityProgress = state.lessonProgress[key][data.activityType];
        if (!activityProgress) {
            state.lessonProgress[key][data.activityType] = [];
        }
        const index = state.lessonProgress[key][data.activityType].findIndex(o => o.user === data.userId);
        if (index < 0) {
            state.lessonProgress[key][data.activityType].push(data.activityProgress);
        } else {
            state.lessonProgress[key][data.activityType][index] = data.activityProgress;
        }
        const overviewIndex = state.lessonProgress[key].overview.findIndex(o => o.user === data.userId);
        if (overviewIndex < 0) {
            state.lessonProgress[key].overview.push(data.lessonProgress);
        } else {
            state.lessonProgress[key].overview[index] = data.lessonProgress;
        }
        state.lessonProgress = { ...state.lessonProgress };
    },
    assessmentProgress(state, data) {
        if (data.sectionId) {
            state.assessmentProgress[`${data.assessmentId}-${data.sectionId}`] = data.progress;
        } else {
            state.assessmentProgress[data.assessmentId] = data.progress;
        }
        state.assessmentProgress = { ...state.assessmentProgress };
    },
    customAssessmentProgress(state, data) {
        if (data.sectionId) {
            state.customAssessmentProgress[`${data.assessmentId}-${data.sectionId}`] = data.progress;
        } else {
            state.customAssessmentProgress[data.assessmentId] = data.progress;
        }
        state.customAssessmentProgress = { ...state.customAssessmentProgress };
    },
    selectedTypingUnit(state, unit) {
        state.selectedTypingUnit = unit;
    },
    selectedTypingLesson(state, lessonId) {
        state.selectedTypingLesson = lessonId;
    },
    selectedTypingPracticeUnit(state, unit) {
        state.selectedTypingPracticeUnit = unit;
    },
    selectedTypingPracticeLesson(state, lessonId) {
        state.selectedTypingPracticeLesson = lessonId;
    },
    assessmentProgress(state, data) {
        if (data.sectionId) {
            state.assessmentProgress[`${data.assessmentId}-${data.sectionId}`] = data.progress;
        } else {
            state.assessmentProgress[data.assessmentId] = data.progress;
        }
        state.assessmentProgress = { ...state.assessmentProgress };
    },
    microbitLessonProgress(state, data) {
        const key = data.sectionId ? `${data.lessonId}-${data.sectionId}` : `${data.lessonId}`;
        state.microbitLessonProgress[key] = data.progress;
        state.microbitLessonProgress = { ...state.microbitLessonProgress };
    },
    unitLessons(state, lessons) {
        state.unitLessons[lessons.unitId] = lessons.lessons;
        state.unitLessons = { ...state.unitLessons };
    },
    unitTypingLessons(state, lessons) {
        state.unitTypingLessons[lessons.unitId] = lessons.lessons;
        state.unitTypingLessons = { ...state.unitTypingLessons };
    },
    unitTypingPracticeLessons(state, lessons) {
        state.unitTypingPracticeLessons[lessons.unitId] = lessons.lessons;
        state.unitTypingPracticeLessons = { ...state.unitTypingPracticeLessons };
    },
    unitTypingProgress(state, data) {
        const progressKey = state.viewAllLatestProgress ? 'latestUnitTypingProgress' : 'unitTypingProgress';
        if (data.sectionId) {
            state[progressKey][`${data.unitId}-${data.sectionId}`] = data.progress;
        } else {
            state[progressKey][data.unitId] = data.progress;
        }
        state[progressKey] = { ...state[progressKey] };
    },
    unitTypingPracticeProgress(state, data) {
        const progressKey = state.viewAllLatestProgress ? 'latestUnitTypingPracticeProgress' : 'unitTypingPracticeProgress';
        if (data.sectionId) {
            state[progressKey][`${data.unitId}-${data.sectionId}`] = data.progress;
        } else {
            state[progressKey][data.unitId] = data.progress;
        }
        state[progressKey] = { ...state[progressKey] };
    },
    typingLessonProgress(state, data) {
        const progressKey = state.viewAllLatestProgress ? 'latestTypingLessonProgress' : 'typingLessonProgress';
        const key = data.sectionId ? `${data.lessonId}-${data.sectionId}` : `${data.lessonId}`;
        if (!state[progressKey][key]) {
            state[progressKey][key] = {};
        }
        state[progressKey][key] = data.progress;
        state[progressKey] = { ...state[progressKey] };
    },
    typingPracticeLessonProgress(state, data) {
        const progressKey = state.viewAllLatestProgress ? 'latestTypingPracticeLessonProgress' : 'typingPracticeLessonProgress';
        const key = data.sectionId ? `${data.lessonId}-${data.sectionId}` : `${data.lessonId}`;
        if (!state[progressKey][key]) {
            state[progressKey][key] = {};
        }
        state[progressKey][key] = data.progress;
        state[progressKey] = { ...state[progressKey] };
    },
    scenesLessons(state, lessons) {
        state.scenesLessons[lessons.unitId] = lessons.challenges;
        state.scenesLessons = { ...state.scenesLessons };
    },
    scenesProgress(state, unit) {
        state.scenesProgress[unit.unitId] = unit.progress;
        state.scenesProgress = { ...state.scenesProgress };
    },
    scenesLessonProgress(state, data) {
        const key = data.sectionId ? `${data.lessonId}-${data.sectionId}` : `${data.lessonId}`;
        state.scenesLessonProgress[key] = data.progress;
        state.scenesLessonProgress = { ...state.scenesLessonProgress };
    },
    microbitUnitLessons(state, lessons) {
        state.microbitUnitLessons[lessons.unitId] = lessons.lessons;
        state.microbitUnitLessons = { ...state.microbitUnitLessons };
    },
    customTypingLessons(state, lessons) {
        if (lessons) {
            state.customTypingLessons = lessons;
            state.customTypingLessons = [...state.customTypingLessons];
        }
    },
    customTypingProgress(state, unit) {
        state.customTypingProgress = unit;
        state.customTypingProgress = { ...state.customTypingProgress };
    },
    lessonActivities(state, data) {
        if (!state.lessonActivities[data.id]) {
            state.lessonActivities[data.id] = data.activities;
        }
        state.lessonActivities = { ...state.lessonActivities };
    },
    updateLesson(state, lesson) {
        const index = state.lessons.findIndex(o => o.id === lesson.id);
        if (index >= 0) {
            state.lessons[index] = lesson;
        } else {
            state.lessons.push(lesson);
        }
        state.lessons = [...state.lessons];
    },
    assessments(state, assessments) {
        state.assessments = assessments;
        state.assessmentsLoaded = true;
    },
    updateAssessment(state, assessment) {
        const index = state.assessments.findIndex(o => o.id === assessment.id);
        if (index >= 0) {
            state.assessments[index] = assessment;
        } else {
            state.assessments.push(assessment);
        }
        state.assessments = [...state.assessments];
    },
    updateCustomAssessment(state, assessment) {
        const index = state.customAssessments.findIndex(o => o.id === assessment.id);
        if (index >= 0) {
            state.customAssessments[index] = assessment;
        } else {
            state.customAssessments.push(assessment);
        }
        state.customAssessments = [...state.customAssessments];
    },
    submission(state, submission) {
        const index = state.submissions.findIndex(o =>
            o.activity_type === submission.activity_type
            && o.item === submission.item
            && o.section === submission.section
            && o.user === submission.student);
        if (index >= 0) {
            state.submissions[index] = submission;
        } else {
            state.submissions.push(submission);
        }
        state.submissions = [...state.submissions];
    },
    challenges(state, challenges) {
        if (!state.challenges[challenges.student]) {
            state.challenges[challenges.student] = [];
        }
        state.challenges[challenges.student] = state.challenges[challenges.student].concat(challenges.items);
        state.challenges = { ...state.challenges };
    },
    updateChallenge(state, challenge) {
        const index = state.challenges[challenge.student].findIndex(o => o.id == challenge.challenge.id);
        state.challenges[challenge.student][index] = challenge.challenge;
        state.challenges = { ...state.challenges };
    },
    checkpoints(state, checkpoints) {
        if (!state.checkpoints[checkpoints.student]) {
            state.checkpoints[checkpoints.student] = [];
        }
        state.checkpoints[checkpoints.student] = state.checkpoints[checkpoints.student].concat(checkpoints.items);
        state.checkpoints = { ...state.checkpoints };
    },
    updateCheckpoint(state, checkpoint) {
        const index = state.checkpoints[checkpoint.student].findIndex(o => o.id == checkpoint.checkpoint.id);
        state.checkpoints[checkpoint.student][index] = checkpoint.checkpoint;
        state.checkpoints = { ...state.checkpoints };
    },
    puzzles(state, puzzles) {
        if (!state.puzzles[puzzles.student]) {
            state.puzzles[puzzles.student] = [];
        }
        state.puzzles[puzzles.student] = state.puzzles[puzzles.student].concat(puzzles.items);
        state.puzzles = { ...state.puzzles };
    },
    projects(state, projects) {
        state.projects = projects;
    },
    project(state, project) {
        state.projects.push(project);
        state.projects = [...state.projects];
    },
    removeProject(state, project) {
        const index = state.projects.findIndex(o => o.name === project);
        if (index >= 0) {
            state.projects.splice(index, 1);
            state.projects = [...state.projects];
        }
    },
    threads(state, threads) {
        state.threads = threads;
    },
    thread(state, thread) {
        const index = state.threads.findIndex(o => o.id === thread.id);
        if (index >= 0) {
            state.threads[index] = thread;
            state.threads = [...state.threads];
        }
    },
    lastTimeLessonProgressUpdated(state, time) {
        state.lastTimeLessonProgressUpdated = time;
    },
    lastTimeExamProgressUpdated(state, time) {
        state.lastTimeExamProgressUpdated = time;
    },
    lastTimeKeyboardingProgressUpdated(state, time) {
        state.lastTimeKeyboardingProgressUpdated = time;
    },
    lessonAnswers(state, data) {
        if (!state.lessonAnswers[data.lessonId]) {
            state.lessonAnswers[data.lessonId] = data.answers;
        }
        state.lessonAnswers = { ...state.lessonAnswers };
    },
    progress(state, progress) {
        state.progress = progress;
    },
    updateProgress(state, progress) {
        progress.forEach(item => {
            const index = state.progress.findIndex(o => o.uid === item.uid && o.item === item.item);
            if (index >= 0) {
                state.progress[index] = item;
            } else {
                state.progress.push(item);
            }
        });
        state.progress = [...state.progress];
    },
    updateProgressLesson(state, lessonProgress) {
        const index = state.progress.findIndex(o => o.item == lessonProgress.item && o.uid == lessonProgress.uid);
        if (index >= 0) {
            state.progress[index] = lessonProgress;
        } else {
            state.progress.push(lessonProgress);
        }
        state.progress = [...state.progress];
    },
    currentSection(state, id) {
        if (id === null) {
            state.currentSection = {};
            return;
        }
        const section = state.sections.find(o => o.id == id);
        if (section) {
            state.currentSection = { ...section };
        }
    },
    updateCurrentSectionPlan(state, activities) {
        const planIndex = state.currentSection.plans.findIndex(o => o.id === activities[0].plan);
        if (planIndex >= 0) {
            state.currentSection.plans[planIndex].activities = activities;
        }
    },
    updateTrackProgress(state, track) {
        state.trackProgress[track.id] = track.progress;
        state.trackProgress = { ...state.trackProgress };
    },
    notifications(state, notifications) {
        state.notifications = notifications || [];
    },
    hasTutorial(state, hasTutorial) {
        state.hasTutorial = hasTutorial;
    },
    updateExamProgress(state, progress) {
        state.examProgress = progress;
    },
    schoolSections(state, sections) {
        state.schoolSections = sections;
    },
    microbitUnits(state, units) {
        state.microbitUnits = units;
    },
    typingUnits(state, units) {
        state.typingUnits = units;
    },
    typingPracticeUnits(state, units) {
        state.typingPracticeUnits = units;
    },
    typingProgress(state, progress) {
        state.typingProgress = progress;
    },
    typingResults(state, results) {
        state.typingResults[`${results.uid}/${results.lesson}`] = results;
    },
    customTypingAssignments(state, assignment) {
        const index = state.customTypingAssignments.findIndex(o => o.id === assignment.id);
        if (index >= 0) {
            state.customTypingAssignments[index] = assignment;
        } else {
            state.customTypingAssignments.push(assignment);
        }
        state.customTypingAssignments = [...state.customTypingAssignments];
    },
    removeCustomTypingAssignment(state, id) {
        const index = state.customTypingAssignments.findIndex(o => o.id === id);
        if (index >= 0) {
            state.customTypingAssignments.splice(index, 1);
            state.customTypingAssignments = [...state.customTypingAssignments];
        }
    },
    lastTimeCustomTypingProgressUpdated(state, time) {
        state.lastTimeCustomTypingProgressUpdated = time;
    },
    customTypingProgress(state, progress) {
        state.customTypingProgress = progress;
    },
    customTypingLessonProgress(state, data) {
        const key = data.sectionId ? `${data.lessonId}-${data.sectionId}` : `${data.lessonId}`;
        state.customTypingLessonProgress[key] = data.progress;
        state.customTypingLessonProgress = { ...state.customTypingLessonProgress };
    },
    customTypingAssignmentResults(state, results) {
        state.customTypingAssignmentResults[`${results.uid}/${results.assignment}`] = results;
    },
    scrollPosition(state, number) {
        state.scrollPosition = number;
    },
    gClassroomCourses(state, courses) {
        state.gClassroomCourses = courses;
    },
    announcements(state, announcements) {
        state.announcements = state.announcements.concat(announcements);
    },
    addAnnouncement(state, announcement) {
        state.announcements.push(announcement);
    },
    topics(state, topics) {
        state.topics = topics;
    },
    searchResults(state, results) {
        state.searchResults = results;
    },
    courses(state, courses) {
        if (!state.courses.length) {
            state.courses = courses;
        } else {
            courses.forEach(course => {
                const index = state.courses.findIndex(o => o.id === course.id);
                if (index >= 0) {
                    if (state.courses[index].units) {
                        course.units.forEach(unit => {
                            const unitIndex = state.courses[index].units.findIndex(o => o.id === unit.id);
                            if (unitIndex >= 0) {
                                if (unit.lessons) {
                                    state.courses[index].units[unitIndex] = unit;
                                }
                            }
                        });
                    } else {
                        state.courses[index] = course;
                    }
                }
            });
            state.courses = [...state.courses];
        }
    },
    course(state, course) {
        const index = state.courses.findIndex(o => o.id === course.id);
        if (index >= 0) {
            state.courses[index] = { ...state.courses[index], ...course };
        }
        state.courses = [...state.courses];
    },
    lastTimeSceneProgressUpdated(state, time) {
        state.lastTimeSceneProgressUpdated = time;
    },
    sceneGroups(state, groups) {
        state.sceneGroups = groups;
    },
    sceneProgress(state, progress) {
        state.sceneProgress = progress;
    },
    sceneResults(state, results) {
        state.sceneResults[`${results.uid}/${results.item}`] = results;
    },
    districtData(state, data) {
        state.districtData = data;
    },
    districtProgress(state, progress) {
        state.districtProgress = progress;
    },
    sectionOrder(state, sectionIds) {
        if (!state.originalSectionOrder) {
            state.originalSectionOrder = state.sections.map(o => o.id);
        }
        state.sections.sort((a, b) => {
            return sectionIds.indexOf(a.id) - sectionIds.indexOf(b.id);
        });
        state.sections = [...state.sections];
    },
    clearSectionOrder(state) {
        state.originalSectionOrder = null;
    },
    schoolData(state, data) {
        state.schoolData[data.data.id] = data;
    },
    schoolProgress(state, data) {
        state.schoolProgress[data.school] = data.progress;
    },
    teacherData(state, data) {
        state.teacherData[data.data.id] = data;
    },
    teacherProgress(state, data) {
        state.teacherProgress[data.teacher] = data.progress;
    },
    customAssessments(state, assessments) {
        state.customAssessments = assessments;
        state.customAssessmentsLoaded = true;
    },
    customAssessment(state, assessment) {
        const index = state.customAssessments.findIndex(o => o.id === assessment.id);
        if (index >= 0) {
            state.customAssessments[index] = assessment;
        } else {
            state.customAssessments.push(assessment);
        }
        state.customAssessments = [...state.customAssessments];
    },
    removeCustomAssessment(state, assessmentId) {
        const index = state.customAssessments.findIndex(o => o.id === assessmentId);
        if (index >= 0) {
            state.customAssessments.splice(index, 1);
            state.customAssessments = [...state.customAssessments];
        }
    },
    lastTimeCustomExamProgressUpdated(state, time) {
        state.lastTimeCustomExamProgressUpdated = time;
    },
    showLockedCurriculum(state, value) {
        state.showLockedCurriculum = value;
    },
    viewAllLatestProgress(state, view) {
        state.viewAllLatestProgress = view;
    },
    viewIntegrationsProgress(state, view) {
        state.viewIntegrationsProgress = view;
    },
    quizQuestions(state, data) {
        state.quizQuestions[data.lessonId] = data.questions;
        state.quizQuestions = { ...state.quizQuestions };
    },
    updateDemoLessonCompletion(state, data) {
        const key = data.sectionId ? `${data.unitId}-${data.sectionId}` : `${data.unitId}`;
        const progress = state.unitProgress[key];
        const index = progress.findIndex(o => o.item === data.lessonId && o.user === data.userId);
        if (index >= 0) {
            state.unitProgress[key][index].finish = new Date();
        }
        state.unitProgress = { ...state.unitProgress };
    },
    viewTimestamps(state, view) {
        state.viewTimestamps = view;
    },
    plansLanguage(state, lang) {
        state.plansLanguage = lang;
    },
    usersTypingStats(state, sectionStats) {
        const { section, stats } = sectionStats;
        state.usersTypingStats[section] = stats;
    },
    usersTypingSettings(state, sectionSettings) {
        const { section, settings } = sectionSettings;
        state.usersTypingSettings = { ...state.usersTypingSettings, [section]: settings };
    }
};
const getLessonProgressKey = state => {
    if (state.viewIntegrationsProgress) {
        if (state.viewAllLatestProgress) {
            return 'latestIntegrationsLessonProgress';
        } else {
            return 'integrationsLessonProgress';
        }
    } else {
        if (state.viewAllLatestProgress) {
            return 'latestLessonProgress';
        } else {
            return 'lessonProgress';
        }
    }
};
const getUnitProgressKey = state => {
    if (state.viewIntegrationsProgress) {
        if (state.viewAllLatestProgress) {
            return 'latestIntegrationsUnitProgress';
        } else {
            return 'integrationsUnitProgress';
        }
    } else {
        if (state.viewAllLatestProgress) {
            return 'latestUnitProgress';
        } else {
            return 'unitProgress';
        }
    }
};
// load user data into the store
export const actions = {
    nuxtServerInit({ commit }, { req }) {
        if (req.user) {
            commit('user', req.user);
        }
    }
};